<template>
  <div>
    <k-crud-page>
      <template #condition>
        <v-form ref="uploadFileForm" v-model="uploadFormValid" class="dlex">
          <div class="dlex_title">上传License文件：</div>
          <c-upload
            v-model="uploadFormValid.file"
            upload-type="btn"
            accept=".lic"
            :rules="[globalRules.empty]"
            :api="uploadFiles"
          ></c-upload>
        </v-form>
      </template>
      <template #resultlist>
        <v-card>
          <div class="list_title">
            License状态：
            <span class="success--text ml-5"
              ><span v-if="status === '01'" class="error--text">未激活</span>
              <span v-if="status === '02'" :class="betweenThirty">激活中</span>
              <span v-if="status === '03'" class="info--text">已失效</span>
              <span
                v-if="expiry_time && status === '02'"
                :class="betweenThirty"
              >
                ({{ expiry_time }})</span
              >
            </span>
          </div>
          <div v-if="status === '02'" class="list_">
            <k-crud-table
              :headers="columns"
              :items="cruddataMX.dataList"
              :paginationshow="false"
            >
              <template #statusName="{ item }">
                <div class="d-flex align-center">
                  <span
                    class="circle mr-2"
                    :class="{
                      'success--text success': showName(item.code) === '已授权',
                      'info--text info': showName(item.code) === '未授权',
                    }"
                  ></span
                  >{{ showName(item.code) }}
                </div>
              </template>
              <template #append>
                <tr>
                  <td class="">网点分析设备</td>
                  <td class="">{{ snList.toString() }}</td>
                </tr>
              </template>
            </k-crud-table>
          </div>
        </v-card>
      </template>
    </k-crud-page>
  </div>
</template>
<script>
import { uploadFiles } from '@/api/upload.js';
export default {
  data() {
    return {
      uploadFiles,
      fileInfo: [],
      uploadFormValid: '',
      crud: {
        name: '',
        title: '', // 在Mixin中被修改
        switch: false,
        widths: '700',
        uploading: false,
        loading: false,
        default: {
          msg: '',
          name: '',
        },
      },
      columns: [
        {
          text: 'License资源',
          class: 'white--text',
          value: 'msg',
          sortable: false,
        },
        {
          text: '状态',
          class: 'white--text',
          value: 'statusName',
          sortable: false,
        },
      ],

      dataList: [],
      snList: [],
      expiry_time: '',
      status: '',
      authorized_list: [], //授权
      unauthorized_list: [], //未授权
    };
  },
  computed: {
    betweenThirty() {
      const nowTime = parseInt(new Date().getTime() / 1000);
      const endTime = parseInt(new Date(this.expiry_time).getTime() / 1000);
      if (nowTime > endTime) {
        return '';
      } else {
        console.log((endTime - nowTime) / 3600 / 24);
        if (parseInt((endTime - nowTime) / 3600 / 24) < 30) {
          return 'warning--text';
        } else {
          return '';
        }
      }
    },
  },
  watch: {},
  created() {
    this.getStatus();
    this.crudInitMX(
      this.crud,
      {
        list: this.$api.mine.mine.getenum,
        formalist: this.formalist,
      },
      {}
    );
  },
  methods: {
    showName(code) {
      if (this.authorized_list.includes(code)) {
        return '已授权';
      } else if (this.unauthorized_list.includes(code)) {
        return '未授权';
      }
    },
    formalist(data) {
      this.dataList = data[0].LicenseEnum;
      return this.dataList;
    },
    getStatus() {
      this.$api.mine.mine.getStatus().then((res) => {
        if (res.code === 200) {
          this.authorized_list = res.data[0].authorized_list;
          this.unauthorized_list = res.data[0].unauthorized_list;
          this.snList = res.data[0].sn_list;
          this.expiry_time = res.data[0].expiry_time.substring(0, 11);
          // this.expiry_time = '2022-07-02 15:30:30';
          this.status = res.data[0].status;
          // this.dataList.map((item) => {
          //   this.authorized_list.map((i) => {
          //     if (item.code === i) {
          //       item.code = '授权';
          //     }
          //   });
          // });
          // this.dataList.map((item) => {
          //   this.unauthorized_list.map((i) => {
          //     if (item.code === i) {
          //       item.code = '未授权';
          //     }
          //   });
          // });
        }
      });
    },
  },
};
</script>
<style>
.list_ .v-data-table-header {
  background: #2e71fe !important;
}
</style>
<style lang="scss" scoped>
.circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}
.dlex {
  display: flex;
  align-items: center;
}
.dlex_title {
  padding: 0 30px;
  font-size: 14px;

  font-weight: 400;
}
.list_ {
  width: 50%;
  padding: 0 0 70px 30px;
}
.list_title {
  height: 106px;
  display: flex;
  font-size: 18px;

  font-weight: bold;
  align-items: center;
  padding-left: 30px;
}
</style>
